<template>
  <div class="videos-section">
    <h4 class="videos-heading">Watch our services in action!</h4>
    <div class="info_section" id="videos">
      <div class="video-wrapper">
        <lazy-youtube-video src="https://www.youtube.com/embed/aIVwv_1HHhU" />
        <label class="caption">Discover Your Place in History</label>
      </div>
      <div class="video-wrapper">
        <lazy-youtube-video src="https://www.youtube.com/embed/tpsEc28jEKs" />
        <label class="caption">What Our Clients Say</label>
      </div>
    </div>
  </div>
</template>

<script>
import LazyYoutubeVideo from 'vue-lazy-youtube-video';

export default {
  components: {LazyYoutubeVideo},
  name: 'VideosSection',
};
</script>

<style lang="scss" scoped>
.videos-section {
  width: 100%;
  max-width: $content-max-width;
  padding: $desktop-content-padding 0 $desktop-content-padding 0;
  margin: auto;

  .videos-heading {
    text-align: center;
    margin-bottom: 40px;
  }

  .info_section#videos {
    display: flex;
    justify-content: space-around;

    .video-wrapper {
      width: 45%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .y-video {
        width: 100%;
      }

      .caption {
        margin-top: 20px;
      }
    }
  }

  @media only screen and (max-width: $breakpoint-tablet) {
    max-width: 100%;

    .info_section#videos {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .video-wrapper {
        width: 80%;

        &:not(:first-child) {
          margin-top: 30px;
        }
      }
    }
  }
}
</style>
