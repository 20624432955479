<template>
  <div class="logo_wall">
    <a href="https://www.bbc.co.uk/news/world-asia-24044335" target="_blank">
      <img
        :data-src="$getAsset('/assets/services/logo_wall/BBC-logo-FCFCF4.png')"
        class="lazyload"
        height="45"
        width="88"
      />
    </a>
    <router-link :to="{name: 'press'}">
      <img :data-src="$getAsset('/assets/services/logo_wall/thesix.png')" class="lazyload" height="40" width="36" />
    </router-link>
    <a href="https://www.nytimes.com/2019/11/28/fashion/travel-custom-heritage-tours-ancestors.html" target="_blank">
      <img
        :data-src="$getAsset('/assets/services/logo_wall/NYT-logo-condensed-FCFCF4.png')"
        class="lazyload"
        height="45"
        width="63"
      />
    </a>
    <a
      href="https://www.scmp.com/week-asia/society/article/2139424/journey-past-why-overseas-chinese-are-finally-embracing-their"
      target="_blank"
    >
      <img
        :data-src="$getAsset('/assets/services/logo_wall/SCMP-logo-FCFCF4.png')"
        class="lazyload"
        height="45"
        width="126"
      />
    </a>
    <a href="https://supchina.com/podcast/searching-for-roots-in-china/" target="_blank">
      <img
        :data-src="$getAsset('/assets/services/logo_wall/SupChina-Sinica-Logo-FCFCF4.png')"
        class="lazyload"
        height="45"
        width="138"
      />
    </a>
    <a href="https://www.youtube.com/watch?v=oBkX0t6r_6k" target="_blank">
      <img
        :data-src="$getAsset('/assets/services/logo_wall/WDYTYA-logo-FCFCF4.png')"
        class="lazyload"
        height="45"
        width="171"
      />
    </a>
    <a
      href="https://blog.500.co/my-china-roots-connects-people-of-chinese-descent-with-long-lost-relatives/"
      target="_blank"
    >
      <img
        :data-src="$getAsset('/assets/services/logo_wall/500startups.png')"
        class="lazyload"
        height="50"
        width="75"
      />
    </a>
    <a href="https://radii.co/tags/my-china-roots" target="_blank">
      <img
        :data-src="$getAsset('/assets/services/logo_wall/Logo-RADII.png')"
        class="lazyload"
        height="45"
        width="183"
      />
    </a>
    <div>
      <img :data-src="$getAsset('/assets/services/logo_wall/bostonpl.png')" class="lazyload" height="45" width="150" />
    </div>
    <div>
      <img :data-src="$getAsset('/assets/services/logo_wall/acpl.png')" class="lazyload acpl" height="56" width="164" />
    </div>
    <div>
      <img :data-src="$getAsset('/assets/services/logo_wall/lapl.png')" class="lazyload" height="45" width="183" />
    </div>
    <div>
      <img
        :data-src="$getAsset('/assets/services/logo_wall/SFPL_LOGO_stacked.png')"
        class="lazyload"
        height="45"
        width="42"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LogoWall',
};
</script>

<style scoped lang="scss">
.logo_wall {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  opacity: 0.5;
  column-gap: 36px;
  row-gap: 24px;

  img {
    height: 45px;
    width: auto;
    @media only screen and (max-width: $breakpoint-mobile) {
      height: 40px;
    }
  }
  img.acpl {
    height: 56px;
  }
}
</style>
